<template>
  <div>
    <nav-bar>
      <div class="edition-center">
        <div class="swiper_wrapper">
          <el-carousel height="525px">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <img
                :src="item.imageUrl | filterPicture"
                @click="handleClickBanner(item)"
                alt=""
                class="swiper_item"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="mt40 notify" v-if="notifyList.length">
        <div class="title">
          Anuncio de notificación
          <div></div>
        </div>
        <div
          class="mt10"
          v-for="(notifyItem, index) in notifyList"
          :key="index + 'notify'"
          style="cursor: pointer"
          @click="toNotifyDetail(notifyItem.id)"
        >
          <notify :notify="notifyItem"></notify>
        </div>
        <div class="flex flex-right">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="2"
            layout="prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
      <div class="" v-if="!loading">
        <div class="mt40 notify flex flex-between align-center">
          <div class="workOrder_title">
            Los más vendidos
            <div></div>
          </div>
          <div
            class="moreTitle flex align-center"
            @click="toList"
            style="cursor: pointer"
          >
            ver más
            <img class="mlr10" src="@/assets/workOrder/more.png" alt="" />
          </div>
        </div>
        <div class="relative">
          <div
            class="oeder mt20"
            v-for="(item, index) in workOrderList"
            :key="index"
            @click="toDetail(item)"
          >
            <workOrder :list="item"></workOrder>
          </div>
        </div>
      </div>
      <loadingVue v-else />

      <div class="addWorkBox flex flex-end">
        <div
          class="btn flex items-center justify-center flex-col"
          @click="handleAddWorkerOrder"
        >
          <img src="@/assets/workOrder/add.png" alt="" />
          <div style="white-space: nowrap">Nuevo PQRS</div>
        </div>
      </div>
    </nav-bar>
    <addWorkOrder
      :show="addWorkDialogVisible"
      @submit="submit"
      @handleClose="addWorkDialogVisible = false"
    ></addWorkOrder>
  </div>
</template>

<script>
import loadingVue from '../../components/loading.vue'
import navBar from '@/components/navBar/index.vue'
export default {
  components: {
    navBar,
    loadingVue
  },

  data() {
    return {
      addWorkDialogVisible: false,
      total: null,
      currentPage: 1,
      query: {
        pageSize: 2,
        pageNo: 1
      },
      bannerList: [],
      notifyList: [],
      workOrderList: [],
      loading: false
    }
  },

  watch: {},

  created() {
    this.getNotifyList()
    this.getWorkOrderList()
    this.getBannerList()
  },

  methods: {
    toDetail(val) {
      console.log('工单详情', val)
      this.$router.push({
        path: '/detail',
        name: 'workOrder-detail',
        params: { detail: val }
      })
    },
    handleAddWorkerOrder() {
      this.addWorkDialogVisible = true
      console.log('点击了')
    },
    getBannerList() {
      this.$axios.post('/banner/auth/list', {}).then((res) => {
        const { rows } = res.data.result
        this.bannerList = rows
        console.log('轮播图', rows)
      })
    },
    getNotifyList() {
      this.$axios.post('/notification/list', this.query).then((res) => {
        this.total = Number(res.data.result.total)
        this.notifyList = res.data.result.rows.map((item) => {
          item.createdTime = item.createdTime.split(' ')
          return item
        })
        console.log('公告通知', res)
        console.log('公告通知', this.notifyList)
      })
    },
    getWorkOrderList() {
      this.loading = true
      try {
        let data = {
          pageSize: 10,
          pageNo: 0
        }
        this.$axios
          .post('/workOrder/list', data)
          .then((res) => {
            this.workOrderList = res.data.result.rows
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      } catch (error) {
        this.loading = false
      }
    },
    handleCurrentChange(val) {
      this.query.pageNo = val
      this.getNotifyList()
      console.log(`当前页: ${val}`)
    },
    toNotifyDetail(val) {
      this.$router.push({
        path: '/notify',
        name: 'notify-detail',
        params: { id: val }
      })
      console.log('公告id', val)
    },
    toList() {
      this.$router.push({ path: '/workOrderList', name: 'workOrderList' })
    },
    submit() {
      setTimeout(() => {
        this.addWorkDialogVisible = false
        this.getWorkOrderList()
      }, 500)
    },
    handleClickBanner(e) {
      e.contentType === 'OBJECT' &&
        this.$router.push({
          path: '/goods',
          name: 'goods',
          params: { id: e.contentDetail.id }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.addWorkBox {
  margin: auto;
  width: 1200px;
  margin-top: 10px;
}
.btn {
  border: solid 1px #e7e7e7;
  width: 76px;
  height: 68px;
  img {
    width: 24px;
    height: 24px;
  }
}
.edition-center {
  width: 1200px;
  margin: 0 auto;

  .swiper_wrapper {
    height: 525px;
    margin: 20px 0;
    border-radius: 20px;
    .swiper_item {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.oeder {
  width: 1200px;
  margin: 0 auto;
}
.notify {
  width: 1200px;
  margin: 0 auto;
  .title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 30px;
    > div {
      margin-top: -10px;
      width: 225px;
      height: 10px;
      background: #8acf66;
    }
  }
  img {
    width: 8px;
    height: 16px;
  }
  .moreTitle {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #979797;
    line-height: 21px;
  }
}
.workOrder_title {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 30px;
  > div {
    margin-top: -10px;
    width: 170px;
    height: 10px;
    background: #8acf66;
  }
}
</style>
